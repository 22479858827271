<template>
  <div class="header flex align-items-center" style="background-color: #fcd535">
    <div class="flex-none ml-3"
    v-if="windowWidth < 760"
    >
      <button><span class="pi pi-bars icons-header"></span></button>
    </div>
    <div class="flex-grow-1 flex align-items-center justify-content-center">
      <img
        src="/layout/images/Logotiquera.png"
        alt="Logotiquera"
        class="img-tiquera"
      />
    </div>
    <div class="flex-none mr-3"
    v-if="windowWidth < 760"
    >
      <button><span class="pi pi-mobile icons-header"></span></button>
    </div>
  </div>
  <div
    class="enterprise-info"
    style="background-color: #404040; color: #fff"
    :class="[windowWidth > 760 ? 'enterprise-info' : 'enterprise-info-mobile']"
  >
  <div class="flex flex-wrap justify-content-center gap-4 sm:gap-4" v-if="windowWidth > 760">
    <div class="mt-4">
      <img src="/images/game/logo_monagas_innova.png" alt="" width="90px" />
    </div>
    <div class="mt-4">
      <h4>Empresa: TEC-FIN212, C.A</h4>
      <div>Proyecto: Sistema Pagando</div>
      <div>Nombre : Richard Orea</div>
      <div>Email: info@tecfin212.com</div>
      <div>Teléfono: +58 414-9958977</div>
    </div>
  </div>
</div>
  <div class="flex align-items-center justify-content-center"
  :class="[windowWidth > 760 ? '' : 'body']"
  >
    <form class="col-2 form">
        <div
            class="title-form text-center flex align-items-center justify-content-center"
        >
            <h5>Consultar cliente para asignar Bracelet</h5>
        </div>
        <div class="form-group">
            <span class="p-float-label mb-4 mt-4 mr-4 ml-4">
                <InputText class="w-full" autocomplete="off" maxlength="10" v-model="dni" required />
                <label :style="{ color: '#404040' }">Documento de identidad *</label>
            </span>
            <span
                class="p-float-label mb-4 mt-4 mr-4 ml-4"
                v-show="ticket"
            >
                <InputText class="w-full" autocomplete="off" maxlength="6" v-model="bracelet" requierd />
                <label :style="{ color: '#404040' }">Código de bracelet *</label>
            </span>
        </div>
        <div class="footer-form">
            <div class="flex align-items-center justify-content-center">
                <button
                    class="btn1 btn-primary"
                    type="submit"
                    @click="searchClientTickets"
                    v-show="!ticket"
                >
                    Consultar
                </button>
                <button
                    class="btn1 btn-primary"
                    type="submit"
                    @click="saveBraceletClientTicket"
                    v-show="ticket"
                >
                    Asignar bracelet
                </button>
            </div>
        </div>
    </form>
  </div>
  <div class="flex align-items-center justify-content-center footer"
  :class="[windowWidth > 760 ? 'footer' : 'footer-mobile']"
  >
    <h6 class="" style="color: #ffff">Copyright Tiquera.com</h6>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      dni: null,
      bracelet: null,
      ticket: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    ...mapActions("tickets", [
      "getTickets",
      "saveBraceletTicket",
    ]),
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    searchClientTickets(e) {
        e.preventDefault();
        this.ticket = null;
        this.getTickets(this.dni)
        .then((response) => {
            this.ticket = response.data.data;
            this.$toast.add({
                severity: "success",
                summary: "Cliente encontrado",
                life: 4000,
            });
        })
        .catch((err) => {
            this.$toast.add({
                severity: "error",
                summary: err.response.data.message,
                detail: err.response.data.errors[Object.keys(err.response.data.errors)[0]][0],
                life: 4000,
            });
        });
    },
    saveBraceletClientTicket(e) {
        e.preventDefault();
        this.saveBraceletTicket({
            bracelet_token: this.bracelet,
            ticket_id: this.ticket.id
        })
        .then(() => {
            this.$toast.add({
                severity: "success",
                summary: "Bracelet asociado al cliente",
                life: 4000,
            });
        })
        .catch((err) => {
            this.$toast.add({
                severity: "error",
                summary: err.response.data.message,
                detail: err.response.data.errors[Object.keys(err.response.data.errors)[0]][0],
                life: 4000,
            });
        });
    }
  }
};
</script>

<style scoped>
.btn1 {
  width: 180px;
  height: 30px;
  background: #fcd535;
  color: #404040;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}
.header {
  height: 100px;
}
.img-tiquera {
  height: 80px;
}
.enterprise-info {
  height: 150px;
}
.enterprise-info-mobile{
  height: 0px;
}
.body{
  background-color: #404040;
  height: max-content;
}
.footer{
  color: #404040;
}
.footer-mobile{
  background-color: #404040;
  width: auto;
  height: 410px;
  border: 5px solid #404040;
  margin-top: -2px;
  color:aliceblue
}
h6{
  position: absolute;
  bottom: 0;
}
.icons-header{
  color: #404040;
  font-weight: 700;
  font-size: x-large;
}
</style>
